import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'

import App from './App.vue'
import router from './router'
import VueFriendlyIframe from 'vue-friendly-iframe'
import VueLoading from 'vuejs-loading-plugin'

Vue.config.productionTip = false

Vue.use(VueFriendlyIframe)

Vue.use(VueLoading)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render (h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecycle-props
          // name: this.name,
          // mountParcel: this.mountParcel,
          // singleSpa: this.singleSpa,
        }
      })
    },
    router
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
